import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['textInput'];

  connect() {
      const rangeInput = this.element.querySelector('input[type="range"]');
      const textInput = this.textInputTarget;

      rangeInput.addEventListener("input", this.updateValue.bind(this));
      textInput.addEventListener("input", this.updateValue.bind(this));

      this.updateValue();
  }

  updateValue(event) {
    const rangeInput = this.element.querySelector('input[type="range"]');
    const textInput = this.textInputTarget;

    const rangeWidth = rangeInput.offsetWidth;
    const newPoint = (rangeInput.value - rangeInput.min) / (rangeInput.max - rangeInput.min);
    let newPlace = 0, offset = 0;

    if (newPoint >= 0 && newPoint <= 1) {
      if (rangeWidth == 0) {
        newPlace = 336 * newPoint;
        offset = newPoint * 100;
      } else {
        newPlace = rangeWidth * newPoint;
        offset = newPoint * 100;
      }
    }

    textInput.style.left = `${newPlace}px`;
    textInput.style.transform = `translateX(-${offset}%)`;

    if (event) {
      const target = event.target;
      if (target.type === "range") {
        textInput.value = rangeInput.value;
      } else if (target.type === "number") {
        rangeInput.value = textInput.value;
      }
    }
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cutoff"
export default class extends Controller {
  static targets = [ "paragraph" ]

  update(e) {
    const checkbox = e.currentTarget;
    const overflowValue = checkbox.checked ? "visible" : "hidden";
    const displayValue = checkbox.checked ? "block" : "-webkit-box";

    this.paragraphTarget.style.overflow = overflowValue;
    this.paragraphTarget.style.display = displayValue;
  }
}
// app/javascript/controllers/show_on_scroll_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("scroll", this.handleScroll);
    this.element.addEventListener('click', this.handleFocus);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollTopArrow = document.getElementById("scroll-top-arrow");

    if (scrollTopArrow) {
      const shouldDisplay = window.scrollY > 200;
      scrollTopArrow.classList.toggle("hidden", !shouldDisplay);
    }
  };

  handleFocus(event) {
    event.preventDefault();
    const targetElement = document.getElementById('yield-wrapper');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

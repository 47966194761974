import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    this.select = new SlimSelect({
      select: this.element,
      addable: function (value) {
        // return false or null if you do not want to allow value to be submitted
        if (value === "bad") {
          // TODO: set values we don't want to allow here.
          return false;
        }

        // Return the value string
        return value; // Optional - value alteration // ex: value.toLowerCase()

        // Optional - Return a valid data object. See methods/setData for list of valid options
        return {
          text: value,
          value: value.toLowerCase(),
        };
      },
    });
  }

  disconnect() {
    this.select.destroy();
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hex", "picker" ]

  updateHex(e) {
    this.pickerTarget.value = e.target.value
  }

  updatePicker(e) {
    this.hexTarget.value = e.target.value
  }

  ensureHashPrefix() {
    if (!this.hexTarget.value.startsWith("#")) {
      this.hexTarget.value = "#" + this.hexTarget.value
    }
  }
}

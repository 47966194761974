// app/javascript/controllers/dynamic_date_min_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.updateMin();
  }

  updateMin() {
    let q_created_at_gteq = document.getElementById("q_created_at_gteq");
    let q_created_at_lteq = document.getElementById("q_created_at_lteq");
    q_created_at_gteq.addEventListener("input", () => {
      q_created_at_lteq.min = q_created_at_gteq.value;
    }
    )
  }
}



import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  toggleRow() {
    let childRows = document.querySelectorAll(`tr[id^="${this.element.id}-"]`);
    for (let row = 0; row < childRows.length; row++) {
      if (childRows[row].classList.contains("hidden")) {
        childRows[row].classList.remove("hidden")
      } else {
        childRows[row].classList.add("hidden")
      }
    }
  }

  navigateClickableElement(event) {
    const { target } = event;
    const element = target.closest("[data-clickable]");

    // Don't navigate if...
    if (!element) return; // Not within data-clickable element
    if (window.getSelection().toString()) return; // There is text selected
    if (target.closest("a")) return; // Clicked a link
    if (target.closest("button")) return; // Clicked a button

    event.preventDefault();
    Turbo.visit(element.dataset.clickable);
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expanded", "collapsed", "wrapper", "icon", "link"]

  connect() {
    this.assignCurrentMode();
    this.updateActiveIcon();
    this.toggleIcon();
    this.toggleSidebarOnResize();
  }

  toggle() {
    this.collapsedTarget.classList.toggle('hidden')
    this.expandedTarget.classList.toggle('hidden')

    if (localStorage.getItem('sidebar')) {
      if (localStorage.getItem('sidebar') === 'collapsed') {
        this.setExpandedMode()
      } else {
        this.setCollapsedMode()
      }
    } else {
      if (document.documentElement.classList.contains('expanded')) {
        this.setExpandedMode()
      } else {
        this.setCollapsedMode()
      }
    }
  }

  assignCurrentMode() {
    if (localStorage.getItem('sidebar') === 'collapsed') {
      this.setCollapsedMode()
    } else {
      this.setExpandedMode()
    }
  }

  setCollapsedMode() {
    this.expandedTarget.classList.add('hidden')
    this.collapsedTarget.classList.remove('hidden')
    this.linkTargets.forEach((link) => {
      link.setAttribute('aria-hidden', 'false')
    })
    localStorage.setItem('sidebar', 'collapsed')
  }

  setExpandedMode() {
    this.expandedTarget.classList.remove('hidden')
    this.collapsedTarget.classList.add('hidden')
    this.linkTargets.forEach((link) => {
      link.setAttribute('aria-hidden', 'true')
    })
    localStorage.setItem('sidebar', 'expanded')
  }

  toggleIcon() {
    if (this.collapsedTarget.classList.contains("hidden")) {
      this.wrapperTarget.classList.add("items-end", "justify-end")
      this.wrapperTarget.classList.remove("items-center", "justify-center")
      this.wrapperTarget.querySelector('button').setAttribute('aria-label', 'Collapse sidebar')
      this.wrapperTarget.querySelector('button').setAttribute('title', 'Collapse sidebar')
      this.iconTarget.classList.remove("fa-up-right-and-down-left-from-center")
      this.iconTarget.classList.add("fa-down-left-and-up-right-to-center")
    } else {
      this.wrapperTarget.classList.add("items-center", "justify-center")
      this.wrapperTarget.classList.remove("items-end", "justify-end")
      this.wrapperTarget.querySelector('button').setAttribute('aria-label', 'Expand sidebar')
      this.wrapperTarget.querySelector('button').setAttribute('title', 'Expand sidebar')
      this.iconTarget.classList.remove("fa-down-left-and-up-right-to-center")
      this.iconTarget.classList.add("fa-up-right-and-down-left-from-center")
    }
  }

  updateActiveIcon() {
    const links = this.element.querySelectorAll("[data-path]");

    links.forEach((link) => {
      const path = link.dataset.path;
      const isActive = path && window.location.pathname.startsWith(path);
      if (isActive) {
        link.classList.add('text-dark_green', 'dark:text-light_green')
      } else {
        link.classList.remove('text-dark_green', 'dark:text-light_green')
      }
    });
  }

  toggleSidebarOnResize() {
    window.addEventListener('resize', () => {
      const screenWidth = window.innerWidth;
      const sidebar = localStorage.getItem('sidebar');
      const previousStatus = localStorage.getItem('previousSidebarStatus');

      if (screenWidth < 768 && sidebar === 'expanded') {
        localStorage.setItem('previousSidebarStatus', sidebar);
        this.setCollapsedMode();
        this.toggleIcon();
      } else if (screenWidth >= 768 && previousStatus === 'expanded') {
        localStorage.setItem('sidebar', previousStatus);
        this.setExpandedMode();
        localStorage.removeItem('previousSidebarStatus');
        this.toggleIcon();
      }
    });
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    const account_number = document.getElementById("customer_number");
    account_number.addEventListener("input", (e) => {
      if (e.target.value.length > 5) {
        this.fetchAccountInfo(e.target.value);
      }
    });

    this.fetchAccountInfo(account_number.value);
  }

  async fetchAccountInfo(account_number) {
    if (account_number.length < 6 || account_number === "pending") {
      return null;
    }
    const url = `/salesforce/account/${account_number}`;
    let csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    try {
      const response = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
      });

      if (response.status !== 200) {
        document.getElementById('sales-force-error').classList.remove('hidden');
        document.getElementById('order-submit').setAttribute('disabled', true);
      } else {
        const data = await response.json();
        if (data.data) {
          document.getElementById('sales-force-error').classList.add('hidden');
          document.getElementById('order-submit').removeAttribute('disabled');

          const companyNameField = document.getElementById("company_name");
          const isReorder = document.querySelector("input[name='reorder_order']").value === "true";

          if (!isReorder) {
            companyNameField.value = data.data["Name"];
          }

          const address = await this.fetchAccountAddress(data.data);
          const a1 = document.getElementById("account_address").querySelector('input[name="address_line1"]');
          const a2 = document.getElementById("account_address").querySelector('input[name="address_line2"]');
          const city = document.getElementById("account_address").querySelector('input[name="city"]');
          const state = document.getElementById("account_address").querySelector('select[name="state"]');
          const zip = document.getElementById("account_address").querySelector('input[name="zip_code"]');
          const country = document.getElementById("account_address").querySelector('select[name="country"]');

          a1.value = address.address_line1;
          a2.value = address.address_line2;
          city.value = address.city;
          state.value = address.state;
          zip.value = address.zip_code;
          country.value = address.country;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  async fetchAccountAddress(address) {
    try {
      const url = `/account_address.json`;
      let csrf = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          reference_number: address.D365_Customer_Id__c,
          zip_code: address.ShippingPostalCode,
          address_line1: address.ShippingStreet,
          city: address.ShippingCity,
          state: address.ShippingState,
          country: address.ShippingCountry,
        }),
      });

      const data = await response.json();
      if (data.error) throw new Error(data.error);
      return data.address;
    } catch (e) {
      console.log("error", e);
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "prevButton", "nextButton", "indicatorDot"];

  connect() {
    const featuredIndex = parseInt(this.element.dataset.featuredIndex);
    this.currentIndex = isNaN(featuredIndex) ? 0 : featuredIndex;

    this.showImage();
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imageTargets.length;
    this.showImage();
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.imageTargets.length) % this.imageTargets.length;
    this.showImage();
  }

  showImage() {
    this.imageTargets.forEach((image, index) => {
      image.classList.toggle("block", index === this.currentIndex);
      image.classList.toggle("hidden", index !== this.currentIndex);
    });

    this.updateIndicator();
  }

  updateIndicator() {
    this.indicatorDotTargets.forEach((dot, index) => {
      dot.classList.toggle("bg-light_green", index === this.currentIndex);
      dot.classList.toggle("border-dark_green", index === this.currentIndex);
      dot.classList.toggle("bg-primary_light", index !== this.currentIndex);
      dot.classList.toggle("border-neutral-400", index !== this.currentIndex);
    });
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errorMessage"];

  connect() {
    this.updateOnLoad()
  }

  updateOnLoad() {
    const requiredFields = document.querySelectorAll('input[required], select[required], textarea[required]');

    requiredFields.forEach(field => {

      const requiredNoticeSibling = field.nextElementSibling;

      if (field.value == null || field.value == "") {
        requiredNoticeSibling.classList.remove("hidden");
      } else {
        requiredNoticeSibling.classList.add("hidden");
      }
    });
  };

  onChange(event) {
    const selectedValue = event.target.value;

    if (selectedValue == null || selectedValue == "" ) {
      this.errorMessageTarget.classList.remove("hidden");
    } else {
      this.errorMessageTarget.classList.add("hidden");
    }
  }
}
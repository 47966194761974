import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const selectElement = document.querySelector("input[name='items']");
    if (selectElement) {
      selectElement.addEventListener('change', this.updateURLFromInput.bind(this));
    }
  }

  updateURLFromInput(event) {
    const itemsPerPage = event.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set("items", itemsPerPage);
    window.location.href = url.toString();
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    threshold: Number
  }

  connect(event) {
    this.toggleFixedClass();
    window.addEventListener('scroll', this.toggleFixedClass.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.toggleFixedClass.bind(this));
  }

  toggleFixedClass() {
    if (window.scrollY > this.thresholdValue) {
      this.element.classList.add('fixed', 'top-0', 'right-0', 'left-0', 'z-50');
    } else {
      this.element.classList.remove('fixed', 'top-0', 'right-0', 'left-0', 'z-50');
    }
  }
}
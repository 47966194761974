import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dark", "light", "state"]

  connect() {
    this.assignCurrentMode()
  }

  toggle() {
    this.lightTarget.classList.toggle('hidden')
    this.darkTarget.classList.toggle('hidden')

    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        this.setDarkMode()
      } else {
        this.setLightMode()
      }
    } else {
      if (document.documentElement.classList.contains('dark')) {
        this.setLightMode()
      } else {
        this.setDarkMode()
      }
    }
  }

  resetSystemMode() {
    localStorage.removeItem('color-theme')
    this.assignCurrentMode()
  }

  assignCurrentMode() {
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      this.setLightMode()
    } else {
      this.setDarkMode()
    }
  }

  setLightMode() {
    this.lightTarget.classList.add('hidden')
    this.darkTarget.classList.remove('hidden')
    this.stateTarget.innerHTML = "Dark mode is now off"
    this.element.querySelector('button').setAttribute('aria-label', 'Switch to dark mode')
    this.element.querySelector('button').setAttribute('title', 'Switch to dark mode')
    document.documentElement.classList.remove('dark')
    localStorage.setItem('color-theme', 'light')
  }

  setDarkMode() {
    this.lightTarget.classList.remove('hidden')
    this.darkTarget.classList.add('hidden')
    this.stateTarget.innerHTML = "Dark mode is now on"
    this.element.querySelector('button').setAttribute('aria-label', 'Switch to light mode')
    this.element.querySelector('button').setAttribute('title', 'Switch to light mode')
    document.documentElement.classList.add('dark')
    localStorage.setItem('color-theme', 'dark')
  }
}
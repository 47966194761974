import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggler', 'toggleable', 'input'];

  conveySelected() {
    const divElement = this.element.querySelector('div');

    if (divElement.classList.contains('text-dark_green')) {
      divElement.classList.remove('text-dark_green');
    } else {
      divElement.classList.add('text-dark_green');
    }

    this.handleChange();
  }

  handleChange() {
    if (this.togglerTarget.getAttribute("aria-expanded") === "true") {
      this.togglerTarget.setAttribute("aria-expanded", "false");
      this.inputTargets.forEach(input => input.setAttribute('disabled', 'true'));
      this.toggleableTarget.setAttribute('aria-hidden', 'true');
    } else {
      this.togglerTarget.setAttribute("aria-expanded", "true");
      this.inputTargets.forEach(input => input.removeAttribute('disabled'));
      this.toggleableTarget.setAttribute('aria-hidden', 'false');

      this.toggleableTarget.focus();
    }
  }

  setActiveLink(event) {
    if (event.target.classList.contains('!bg-light_green')) {
      event.target.classList.remove('!bg-light_green');
      event.target.classList.remove('!text-primary_dark');
    } else {
        event.target.classList.add('!text-primary_dark');
        event.target.classList.add('!bg-light_green');
      }
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "password", "button", "errorMessage"];

  connect() {
    this.checkEmail();
  }

  checkEmail() {
    const emailValue = this.emailTarget.value;
    const freeEmailDomains = ["outlook.com", "gmail.com", "yahoo.com", "hotmail.com", "aol.com", "aim.com", "icloud", "zoho", "yandex", "mail.com", "me.com", "live.com", "mac.com", "comcast.net", "msn.com", "bellsouth.net", "sbcglobal.net"];

    if (emailValue.includes('valleyforge')) {
      if (this.hasPasswordTarget) {
        this.passwordTarget.disabled = true;
      }
      this.buttonTarget.disabled = true;
      this.errorMessageTarget.classList.remove("hidden");
      this.errorMessageTarget.textContent = "Please sign in with DUO (Microsoft) instead of registering for a new account.";
    } else if (freeEmailDomains.some(domain => emailValue.includes(domain))) {
      if (this.hasPasswordTarget) {
        this.passwordTarget.disabled = true;
      }
      this.buttonTarget.disabled = true;
      this.errorMessageTarget.classList.remove("hidden");
      this.errorMessageTarget.textContent = "Please register with your business email or contact your Sales Rep to enable your Design Library access.";
    } else {
      if (this.hasPasswordTarget) {
        this.passwordTarget.disabled = false;
      }
      this.buttonTarget.disabled = false;
      this.errorMessageTarget.classList.add("hidden");
    }
  }
}

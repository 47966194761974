import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'address_fields', 'addressLine1', 'addressLine2', 'city', 'state', 'country', 'zip'];

  connect() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const selectedValue = this.inputTarget?.value;
    const addressFields = this.address_fieldsTarget;

    if (!addressFields) {
      console.error('Address fields target is not defined');
      return;
    }

    const isVisible = selectedValue && selectedValue !== 'Give to Requestor';
    addressFields.classList.toggle('hidden', !isVisible);
    addressFields.setAttribute('aria-hidden', String(!isVisible));

    if (selectedValue === 'Give to Requestor') {
      this.prepopulateVFFAddress();
    }
  }

  prepopulateVFFAddress() {
    const address = {
      line1: '1650 West McNab Road',
      line2: '',
      city: 'Fort Lauderdale',
      state: 'FL',
      country: 'USA',
      zip: '33309'
    };

    if (this.addressLine1Target) this.addressLine1Target.value = address.line1;
    if (this.addressLine2Target) this.addressLine2Target.value = address.line2;
    if (this.cityTarget) this.cityTarget.value = address.city;
    if (this.stateTarget) this.stateTarget.value = address.state;
    if (this.countryTarget) this.countryTarget.value = address.country;
    if (this.zipTarget) this.zipTarget.value = address.zip;
  }
}

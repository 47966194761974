import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  careers(event) {
    event.preventDefault();
    const targetElement = document.getElementById('panel_open_positions');
    if (targetElement) {
      targetElement.focus();
    }
  }
}
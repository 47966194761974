import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tempTotalOrdered", "totalOrdered"];

  connect() {
    this.updateTotalOrdered();
  }

  update() {
    this.updateTotalOrdered();
  }

  updateTotalOrdered() {
    const tempValue = parseFloat(this.tempTotalOrderedTarget.value);
    const totalValue = tempValue * 0.11;
    this.totalOrderedTarget.value = totalValue.toFixed(2);
  }
}
